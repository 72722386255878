@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

$theme-colors: (
  primary: $primary-color,
  secondary: $secondary-color,
);

.bg-primary,
.badge-primary,
.alert-primary {
  background: $primary-background;
  border-color: $primary-color;
}

.col-container {
  max-width: 98%;
  margin: auto;
}

@import "bootstrap";

@import "bootstrap/scss/grid";
