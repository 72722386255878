.primary-button {
  color: var(--white-color) !important;
  background: var(--primary-background);
  border-color: var(--primary-color);
  transition: 0.5s ease-out;
}

.primary-button:hover {
  color: var(--white-color);
  background: var(--primary-hover-background);
  border-color: var(--primary-color);
  transition: 0.5s ease-out;
} 

.primary-button-outline {
  color: var(--primary-color);
  border: 1px solid;
  border-color: var(--primary-color);
  transition: 0.5s ease-out;
}

.primary-button-outline:hover {
  color: var(--white-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
  transition: 0.5s ease-out;
} 

.primary-button-flat {
  color: var(--primary-color); 
  background: rgba(21, 165, 179, 0.1);
  transition: 0.5s ease-out;
}

.primary-button-flat:hover {
  color: var(--primary-color);
  background: rgb(21, 165, 179, 0.3);;
  transition: 0.5s ease-out;
} 

.secondary-button {
  color: var(--secondary-color);
  background-color: var(--white-color);
  // transition: 0.5s ease-out;
  border: 1px solid var(--bs-gray-300);
}

.secondary-button:hover {
  color: var(--secondary-color);
  box-shadow: inset 0 0 15px var(--lightgray-color);
  background-color: var(--white-color);
  border: 1px solid var(--lightgray-color);
  transition: 0.5s ease-out;
}

.secondary-button-outline {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  background-color: var(--white-color);
}

.secondary-button-outline:hover {
  color: var(--white-color);
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  transition: 0.5s ease-out;
}

.tertiary-button {
  color: var(--white-color);
  background: var(--secondary-color-light);
  transition: 0.5s ease-out;
}

.tertiary-button:hover {
  color: var(--white-color);
  background: var(--secondary-color);
  border: 1px solid var(--lightgray-color);
  transition: 0.5s ease-out;
}

.button {
  margin-left: 20px;
}

.button-left {
  margin-right: 20px;
}

.buttons {
  &__end {
    display: flex;
    justify-content: flex-end;
  }

  &__start {
    display: flex;
    justify-content: flex-start;
  }
  
  &__space-between {
    display: flex;
    justify-content: space-between;
  }

  button {
    margin-top: auto;
  }
}

.btn-row {
  margin-top: 10px;
  width: 100%;

  .col {
    width: 50%;
    float: left;
    padding: 0;
    margin-bottom: 10px;

    &--15 { width: 15%; }
    &--20 { width: 20%; }
    &--80 { width: 80%; }
    &--85 { width: 85%; }
  }
}

.buttonsRow {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  width: 100%;
  float: right;

  button.btn {
    margin-left: 10px;
  }

  button{
    img{
      margin-right: 10px;
    }
    span{
      vertical-align: middle;
    }
  }
}

// custom css for Angular Material buttons
.mat-button-toggle-checked {
  background-color: var(--primary-hover-color) !important;
  color: white !important;
}

.mat-button-toggle-group {
  border-radius: 6px !important;
}

.mat-button-toggle {
  background-color: white;
  color: rgba(0,0,0,0.78);
}

// fixed disabled text color of primary button
.primary-button.btn:disabled {
  color: var(--white-color) !important;
}

.checkbox-form-field {
  background: whitesmoke;
  border-radius: 6px;
  padding: 6px;
}

.action-sheet-menu {
  position: absolute !important;
  z-index: 99999;
  bottom: 10px;
  right: 20px;
}

.filter-mobile-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.mobile {
  &__filter {
    display: flex;
    justify-content: space-evenly;
    
    .mat-icon {
      height: auto;
      width: auto;
    }
    
    .material-icons {
      padding-right: 4px;
      margin-top: 2px;
      font-size: 16px !important;
    }
  }
}

// button with matIcon
.btn {
  &__container {
    display: flex;
  }

  &__icon {
    font-size: 16px !important;
    display: flex;
    margin: auto !important;
    height: auto !important;
    width: 24px !important;
  }
}

.kanban-btn {
  margin: 12px 0;

  &__label-before {
    margin-right: 6px;
  }

  &__label-after {
    margin-left: 6px;
  }
}
