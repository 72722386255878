//  Stylizacja mat-steppera względem projektu
.stepper-container{
  margin-top: 0 !important;
  background-color: transparent !important;

  mat-vertical-stepper{
    border-radius: 6px;
    background-color: transparent !important;
  }
}

.mat-vertical-content{
  padding: 0 24px 10px 24px !important;
}

.mat-step{
  background-color: var(--white-color);
  border-radius: 6px;
  margin-bottom: 10px;
}

.mat-vertical-content-container {
  margin-left: 0 !important;
}

.mat-vertical-stepper-header {
  padding: 16px 24px !important;
}

.mat-stepper-vertical-line::before{
  content: none !important;
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0,0,0,0);
}

// css wykorzyystywane w stepperach
.stepper-btn{
  &__space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__right-position{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__left-position{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__summary-buttons{
    margin-bottom: 10px;
  }

  &--margin-top{
    margin-top: 20px;
  }

  button{
    margin: 10px 0;
    width: 120px;
  }
}

.form-stepper-header{
  font-size: 24px;
  font-weight: bold;
  padding: 15px 0;
}

.stepper-icon{
  &__left{
    margin-right: 10px;
    margin-bottom: 2px;
    height: 15px;
  }
  &__right{
    margin-left: 10px;
    margin-bottom: 2px;
    height: 15px;
  }
}

.step-label{
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

button:hover img.stepper-icon__left{
  filter: invert(1);
}