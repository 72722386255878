.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet-active{
  background-color: var(--primary-color) !important;
}

.swiper-button-prev, .swiper-button-next {
  color: var(--primary-color) !important;
  transform: scale(0.5);
}