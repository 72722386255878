$black: #121212;
@mixin media-query-for-mobile {
  @media (max-width: 768px) and (min-width: 1px) {
    @content;
  }
}
mat-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-header-pagination {
      display: none !important; // <== disable pagination
    }
    .mat-mdc-tab-label-container {
      left: 0px; // if you need to use it on mobile - set left position to 0
      width: 100%;
      .mat-mdc-tab-list {
        overflow-x: auto !important; // <== set horisontal scroll bar imperatively
        // below rule prevents sliding of buttons' container - because it not sliding properly - to left it not slide as well
        transform: none !important;

        &::-webkit-scrollbar { // TO Remove horizontal scrollbar in tabs
          display: none;
        }
        &::-webkit-scrollbar {
          width: 5px;
          height: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--primary-hover-color);
        }
        &::-webkit-scrollbar-thumb:hover {
          background: var(--primary-color);
        }
        
        .mat-mdc-tab-labels {
          // some tweaks for tabs - up to you
          @include media-query-for-mobile {
            justify-content: unset !important; 
          }
          .mat-mdc-tab-label {
            // min-width: 20% !important;
            padding: 1.25% !important;
            margin: 0px !important;
            text-transform: uppercase;
            color: $black;
            font-weight: 600;
            min-width: 140px !important;
          }
        }
      }
    }
  }

  
}