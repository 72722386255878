.mat-mdc-dialog-container {
  padding: 0 !important;
}

.mat-mdc-dialog-title {
  margin: 0 !important;
  padding: 0px 24px 12px !important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  color: white !important;
}

.dialog-content {
  padding: 18px 24px 24px 24px;
  overflow: auto;
  max-height: calc(95dvh - 100px);

  &--full-height {
    max-height: calc(100dvh - 75px) !important;
  }
}

.success-dialog {
  &__background {
    background-color: var(--success-color);
    color: var(--white-color);
  }
  &__button {
    background: var(--success-color);
    border-color: var(--success-color);
    color: var(--white-color);

    &:hover{
      background: var(--green-color);
      border-color: var(--success-color);
      color: var(--white-color);
    }
  }
}

.info-dialog {
  &__background{
    background-color: var(--secondary-color);
    color: var(--white-color);
  }
  &__button {
    background: var(--secondary-color-light);
    border-color: var(--secondary-color);
    color: var(--white-color);

    &:hover{
      background: var(--secondary-color);
      border-color: var(--secondary-color);
      color: var(--white-color);
    }
  }
}

.danger-dialog {
  &__background {
    background-color: var(--error-color);
    color: var(--white-color);
  }
  &__button{
    background: var(--error-color);
    border-color: var(--error-color);
    color: var(--white-color);

    &:hover{
      background: var(--red-color);
      border-color: var(--error-color);
      color: var(--white-color);
    }
  }
}

.dialog {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px !important;
    min-height: 62px;

    &--text {
      margin: 0;
    }
  }

  &__back-btn {
    cursor: pointer;

    &:hover {
      color: var(--primary-hover-color);
      transition: 0.5s ease-in-out;
    }
  }

}