.container__center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #e2e3e5;

  .content {
    background: #fff;
    max-width: 450px;
    // min-width: 450px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 3px 4px 8px 0px rgba(0,0,0,0.15);

    img {
      text-align: left;
      object-fit: contain;
    }

    .divider {
      margin: 5px 0;
      border-bottom: 2px solid #E9E9E9;
    }

    .header {
      margin: 20px 0;
      text-align: left;
      font-weight: 500;
      font-size: 1.4em;
    }


    .footer {
      margin: 20px 0;
      opacity: .6;
      text-align: center;
      font-size: 10px;
    }
  }

}
