@import "./theme/variables.scss";
@import "./theme/cutom-bootstrap.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./theme/small-container";
@import "./theme/custom-mat-stepper.scss";
@import "./theme/custom-buttons.scss";
@import "./theme/custom-scrollbar.scss";
@import "./theme/custom-dialog.scss";
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/autoplay";
@import "./theme/custom-swiper.scss";
@import "./theme/mat-tabs-override.scss";
@import "./theme/mobile-card.scss";

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto, "Roboto", sans-serif;
  font-size: 16px;
  background-color: #f1f1f1;
}

a.click {
  text-decoration: none;
  color: var(--primary-color);
}

a.click:hover {
  color: var(--primary-hover-color);
}

h3 {
  text-align: center;
  padding: 10px;
  color: var(--secondary-color);
  width: 100% !important;
  min-height: 2rem;
}

/*
* Container for each page, setting equl box padding.
*/

.page-content-container {
  padding: 10px 20px;
  min-height: calc(100vh - 60px);
  border-radius: 6px;

  &--mobile {
    padding: 10px;
  }

  &.max-800 {
    max-width: 800px;
    margin: auto;
    min-height: auto;
    margin-top: 50px;
    background-color: var(--white-color);
  }

  &.max-600 {
    max-width: 600px;
    margin: auto;
    min-height: auto;
    margin-top: 50px;
    background-color: var(--white-color);
  }

  &__header {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .tools {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
}

.mat-drawer-content {
  background-color: #f1f1f1;
}

.container {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #405065;
}

.search {
  max-width: 300px;
  min-width: 200px;
}

.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

mat-form-field {
  width: 100%;
}

mat-checkbox {
  font-size: smaller;
}

.error {
  color: var(--primary-color);
}

.error-container {
  background: var(--dirty-white-color);
  border-radius: 5px;
  padding: 10px 5px;
  margin: 10px 0;
  color: red;
  text-align: center;
}

.info-container {
  background: var(--dirty-white-color);
  border-radius: 5px;
  padding: 10px 5px;
  margin: 10px 0;
  color: grey;
  text-align: center;
}

.expand-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}

.expand-button.rotated {
  transform: rotate(180deg);
}

.expand-container {
  overflow-y: hidden;
  transition: 300ms ease-in-out;

  height: auto;
  transform: scaleY(1); // implicit, but good to specify explicitly
  transform-origin: top; // keep the top of the element in the same place. this is optional.
}

.expand-container.expanded {
  height: 0;
}

.expand-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.no-bottom {
  margin-bottom: -1.25em !important;
}

span.symlink {
  mat-icon {
    margin-right: 2px;
    transform: scale(0.8);
  }

  display: flex;
  align-items: center;

  font: 14px Roboto;
  color: var(--primary-hover-color);
  cursor: pointer;
  margin: 0 10px;
}

.company-logo-container {
  img {
    width: 80%;
  }

  display: flex;
  justify-content: center;
  width: 100%;
}

.header {
  p {
    color: var(--secondary-color);
    text-align: center;
    font-size: 21px;
    font-weight: normal;
  }

  &--height-default {
    height: unset !important;
  }
}

.table-container {
  table {
    width: 100%;
    margin-bottom: 16px;

    .table-header {
      font-size: 16px;
      font-weight: bold;
      padding: 16px 5px;
    }
  }

  tr {
    th {
      text-align: left;
      padding: 8px;
    }

    td {
      font-size: 14px;
      font-weight: 525;
      text-align: left;
      padding: 8px;
      width: 50%;
    }
  }

  tr:nth-child(even) {
    background-color: var(--dirty-white-color);
  }

  &--pagination {
    max-height: calc(100vh - 360px) !important;

    @media (max-width: 1919px) {
      max-height: calc(100vh - 400px) !important;
    }
  }

  &--pagination-more-filters {
    @media (min-height: 600px) {
      max-height: calc(100vh - 420px) !important;
    }
  }

}

.tr-no-color {
  background-color: unset !important;
}

.row-divider {
  border-top: 1px solid var(--lightgray-color);
}

.page-header {
  margin: 20px 0;
  .text-header {
    font-size: 18px;
    font-weight: bold;
    float: left;
    @media (max-width: 1150px) {
      text-align: center;
    }
  }

  table{
    width: 100%;
    tr{
      width: 100%;
      td{
        width: 50%;
        @media (max-width: 1150px) {
          width: 100%;
          float: left;
        }
      }
    }
  }
}

.main-header {
  font-size: 16px;
  float: left;
}

.to-right-in-table {
  text-align: end !important;
}

.white-text {
  color: var(--white-color);
}

.btn-on-white-bg {
  border: 1px solid var(--lightgray-color);
}

// Usuniecie niebieskiej otoczki na przyciskach
.btn:focus {
  box-shadow: none;
}

.custom-alert {
  color: var(--error-color);
  font-size: 12px;
  margin: 2px 0 10px;
}

.width {
  &__10 {
    width: 10% !important;
  }
  &__20 {
    width: 20% !important;
  }
  &__30 {
    width: 30% !important;
  }
  &__35 {
    width: 35% !important;
  }
  &__40 {
    width: 40% !important;
  }
  &__60 {
    width: 60% !important;
  }
  &__70 {
    width: 70% !important;
  }
  &__80 {
    width: 80% !important;
  }
  &__100 {
    width: 100% !important;
  }
}

.table-without-header {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;

  th, td {
    text-align: left;
    padding: 10px 20px;
    font-size: 14px;
    width: 50%;
  }

  tr { background-color: var(--dirty-white-color); }
  tr:nth-child(even) td { background-color: var(--white-color); }
}

.modal-content {
  transform: translateY(100%);
}

span.fonts {
  mat-icon {
    margin-right: 2px;
    transform: scale(0.7);
  }
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-vertical {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;

  th {
    text-align: left;
    padding: 0 0 0 5px;
    font-size: 14px;
  }

  td {
    text-align: left;
    padding: 10px;
    font-size: 14px;
  }

  tr { background-color: var(--white-color); }
  tr:nth-child(even) td { background-color: var(--dirty-white-color); }

  &__header{
    color: var(--lightgray-color);
    text-transform : uppercase;
  }
}

.pointer {
  cursor: pointer;
}

.tool-row {
  display: flex;
  flex-wrap: wrap;

  &__info-width {
    width: 25px;
  }
  &__tools-width {
    width: calc(100% - 25px)
  }

  &--info-padding {
    padding: 8px 0 0 0;
  }
}

.range-divider {
  &__top {
    margin-top: 0;
  }
  &__bottom {
    margin-bottom: 0;

    &--5 {
      margin-bottom: 5px;
    }
    &--10 {
      margin-bottom: 10px;
    }
  }
}

.search-box-sm {
  color: #ced4da;
  input:focus {
    box-shadow: 0 0 5px var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  input {
    font-size: 0.75rem;
  }
}

.search-box-sm.icon {
  display: inline-block;
  position: relative;
}

.search-box-sm.icon::after {
  font-family: "FontAwesome";
  content: "\f002";
  color: var(--primary-color);
  position: absolute;
  right: 10px;
  top: 3px;
}

.no-filters {
  max-height: calc(100vh - 185px) !important;
}

.mat-mdc-input-element {
  box-sizing: border-box !important;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  &:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
}

.switch-section {
  max-height: calc(100vh - 245px) !important;

  &--display {
    display: none !important;
  }

  &--with-filter {
    max-height: calc(100vh - 275px) !important;
  }
}

.loader {
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--full-height {
      min-height: 70vh;
    }
  }
  &__position {
    display: flex;
    margin: auto;
    justify-content: center;
  }
  &__padding {
    padding: 50px;
  }
}

// custom css override snackbars css after changes in angular material 15 
.custom-snack-bar {
  color: var(--white-color) !important;

  .mdc-snackbar__surface {
    display: flex !important;
    justify-content: space-around !important;
    min-width: unset !important;
    max-width: unset !important;
    padding: 4px 8px !important;
    margin-top: 8px;
  }
  
  &__success.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: var(--success-color) !important;
  }
    
  &__error.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: var(--error-color) !important;
  }
}

//breadcrumbs
.xng-breadcrumb-root {
  margin: 10px 0 0px 5px !important;
}
.xng-breadcrumb-item {
  font-size: 14px;
  font-weight: 500;
}

.xng-breadcrumb-trail {
  color: var(--ion-dark-grey) !important;
}

.xng-breadcrumb-link {
  color: #000 !important;
}

.breadcrumb-margin {
  margin-left: 15px;
}

// building addres in stepper 
.assitance-adress {
  &__container {
    white-space: normal !important;
    line-height: normal !important;
  }
  &__image {
   width: 40px; 
  }
  &__column {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }
  &--display {
    display: flex !important;
  }
}

// fix displaying native input icons in Angular Materials v15
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.custom-tooltip .mdc-tooltip__surface {
  max-width: unset;
}

/* utility classes */ 
.margin {
  &--auto {
    margin: auto;
  }
}

.error-container {
  background: var(--dirty-white-color);
  border-radius: 5px;
  padding: 10px 5px;
  margin: 10px 0;
  color: red;
  text-align: center;
}

.default-cursor {
  cursor: default;
}

.mat-mdc-paginator-range-label {
  @media(max-width: 768px) {
    margin: 0 !important;
  }
}

.overflow {
  &--auto {
    overflow: auto !important;
  }
  &--hidden {
    overflow: hidden !important;
  }
  &--scroll {
    overflow: scroll !important;
  }
  &--visible {
    overflow: visible !important;
  }
}

.margin {
  &__auto {
    margin: auto;
  }
  &__top {
    &--6 {
      margin-top: 6px;
    }
    &--8 {
      margin-top: 8px;
    }
    &--12 {
      margin-top: 12px;
    }
  }
  &__bottom {
    &--0 {
      margin-bottom: 0 !important;
    }
    &--6 {
      margin-bottom: 6px;
    }
    &--8 {
      margin-bottom: 8px;
    }
    &--12 {
      margin-bottom: 12px;
    }
  }
  &__0 {
    margin: 0;
  }
}


.justify-content {
  &--end {
    justify-content: end;
  }
}

.flex-direction {
  &--column {
    flex-direction: column;
  }
}

.box-shadow {
  &--unset {
    box-shadow: unset !important;
  }
}


// custom css for expansion-panel
.mat-expansion-panel-header {
  padding: 0 24px 0 12px !important;
}
.mat-expansion-panel-body {
  padding: 0 12px 16px !important;
}

.loader-in-btn {
  max-height: 20px;
}
