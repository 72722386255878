$primary-color: #15a5b3;
$primary-hover-color: #2cdcec;
$primary-background: linear-gradient(270deg, #2cdcec 0%, #15a5b3 100%);
$primary-hover-background: linear-gradient(270deg, #15a5b3 0%, #15a5b3 100%);

$secondary-color: #323232;
$secondary-color-light: #474747;

$green-color: #6BC54B;
$black-color: #000000;
$white-color: #ffffff;
$dirty-white-color: #f5f5f5;
$lightgray-color: #b7bac1;
$gray-color: #93979F;
$lightblue-color: rgb(66, 133, 244, 0.09);
$error-color: #f44336;
$success-color: #00c851;
$box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
$red-color: #ff1100;

:root {
  --primary-color: #{$primary-color};
  --primary-hover-color: #{$primary-hover-color};
  --primary-background: #{$primary-background};
  --primary-hover-background: #{$primary-hover-background};

  --secondary-color: #{$secondary-color};
  --secondary-color-light: #{$secondary-color-light};

  --green-color: #{$green-color};
  --black-color: #{$black-color};
  --white-color: #{$white-color};
  --dirty-white-color: #{$dirty-white-color};
  --lightgray-color: #{$lightgray-color};
  --gray-color: #{$gray-color};
  --lightblue-color: #{$lightblue-color};
  --error-color: #{$error-color};
  --success-color: #{$success-color};
  --box-shadow: #{$box-shadow};
  --red-color: #{$red-color};
}
