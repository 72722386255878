.card-view {
  &__container {
    padding: 12px 0;
  }

  &__row {
    background-color: whitesmoke;
    padding: 12px;
    border-radius: 6px;
    width: 100%;
    border: solid 0.5px whitesmoke;
  }

  &__multi-row {
    white-space: pre-line;
  }

  &__indicator{
    &--row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &--dot {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }
  }

  &__col {
    display: flex;
    margin: auto;
    word-break: break-all;
  }

  &__prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  &__no-padding {
    padding: 0 !important;
  }

  &__no-margin {
    margin: unset;
  }
}

.no-data-to-show {
  text-align: center;
  margin: 30px;
  font-size: 16px;
}

.custom-card {
  box-shadow: none !important;
  border-radius: unset !important;

  &__padding-bottom {
    padding-bottom: 12px !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid lightgray !important;
  }
}

.row-delete {
  transition: all 0.2s ease-in-out;
  text-align: end;

  &__container {
    margin-left: 75%;
  }

  &--icon {
    cursor: pointer;

    &:hover {
      color: var(--primary-hover-color);
      scale: 0.9;
    }
  }
}

.pointer-hover { 
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: var(--dirty-white-color);
    border: solid 1px var(--lightgray-color);
    scale: 0.99;
    transition: all 0.3s ease-in-out;
  
    .card-view__row {
      border: solid 0.5px var(--lightgray-color);
      transition: all 0.6s ease-in-out;
    }
  }
}
