.custom-scrollbar {
  // obsługa scrollbara na przeglądarce Firefox
  scrollbar-width: thin;
  scrollbar-color: #2cdcec #ffffff;
  
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-hover-color);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
  }

  &__in-table{
    // obsługa scrollbara na przeglądarce Firefox
    scrollbar-width: thin;
    scrollbar-color: #2cdcec #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--primary-hover-color);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--primary-color);
    }

    ::-webkit-scrollbar-corner { 
      background: rgba(0,0,0,0.5) !important;
    }
  }

  &__danger {
    scrollbar-width: thin;
    scrollbar-color: #ff0000 #ffffff;
    
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-track:hover {
      background: #f7f7f7;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--error-color);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--red-color);
    }
  }
}

.mat-drawer-inner-container {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-hover-color);
  }
}